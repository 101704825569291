@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&family=Montserrat:wght@500&display=swap');
.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 20;
  left: 0;
  background-color: var(--sc);
  box-shadow: 4px 0 5px -5px #888;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  //overflow: hidden;
  transition: left 0.4s;
  .baby{
    font-size: 24px;
    color: var(--blue);
    margin-bottom: 40px;
    font-family: 'Mochiy Pop P One', sans-serif;
    width: 100%;
    padding-left: 10%;
    text-align: left;
  }

  &.close {
    left: -250px;
  }

  .inactive {
    color: gray;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    padding: 12px 0 12px 15px;
    text-align: left;
    width: 80%;
    margin-bottom: 10px;
    transition: width 0.3s;
    font-family: var(--montserrat);


    &.active {
      background-color: var(--blue);
      color: var(--white);
      width: 100%;
      padding: 12px 0 12px 15px;
      position: relative;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      &:hover{
        width: 100%;
      }
    }

    &:hover {
      background-color: var(--blue);
      color: var(--white);
      width: 80%;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
}