.layout{
  margin-left: 250px;
  transition: margin-left 0.4s;
  height: auto;
  padding-bottom: 40px;
  padding-top: 100px;
  //background-color: red;
  &.cl{
    margin-left: 0px;
  }
}