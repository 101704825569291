*{
    box-sizing: border-box;

}
progress{
    -webkit-writing-mode: horizontal-tb !important;
    appearance: auto;
    box-sizing: border-box;
    display: inline-block;
    height: 1em;
    width: 10em;
    background-color: red !important;
    border-radius: 2rem !important;
    vertical-align: -0.2em;
}