.header {
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: white;
  z-index: 20;

  top: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  &__bur {
    margin-left: 260px;
    font-size: 20px;
    transition: margin-left 0.4s;
    &--display{
      cursor: pointer;
    }

    &.br {
      margin-left: 20px;
    }
  }
  &__login{
    margin-right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: var(--montserrat);
    cursor: pointer;
    div{
      margin-left: 5px;
    }
    &:hover{
      text-decoration: underline;
    }
  }
}