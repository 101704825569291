.wrapperopVideo {
  //height: 100vh;
  width: 100%;
  position: relative;
  font-family: var(--montserrat);
  padding-bottom: 100px;
  .wro{
    display: flex;
    align-items: center;
    .icons{
     font-size: 32px;
      color: var(--blue);
    }
    .addVideos {
      font-size: 32px;
      display: flex;
      margin-left: 5%;
      padding: 20px 10px;
      color: var(--blue);
    }

  }

  .containerer {
    border-radius: 10px;

    .wr {
      padding-bottom: 40px;
      width: 90%;
      margin-left: 5%;
      border-radius: 10px;

      .section {
        padding-right: 0px;
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        background-color: rgba(#C1C1C1, 0.4);
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-left: 15px;
      }

      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      .titleImgg {
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;

        .allWr {
          display: flex;
          width: 70%;
          margin-right: 10px;

          .category {
            padding-left: 10px;
            display: flex;
            align-items: center;
            width: 100%;

            .categoryIn {
              font-size: 18px;
              font-family: var(--montserrat);
            }

            select {
              padding: 10px 5px;
              margin-left: 20px;
              outline: none;

              .opt {
                padding: 10px;
              }
            }
          }
        }

        .inputt {
          width: 40%;
          display: flex;
          align-items: center;
          margin-left: 15px;

          .titleOnee {
            line-height: 1.5rem;
            padding-right: 5px;
          }

          .input11 {
            display: flex;
            letter-spacing: 1px;
            width: 60%;
            font-size: 14px;
            padding: 10px 10px;
            font-family: 'Roboto', sans-serif;
            color: #263238;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            outline: none;
            background-color: transparent;


            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              font-size: 14px;
            }
          }


        }
        .inputti {
          width: 50%;
          display: flex;
          align-items: center;
          margin-left: 15px;

          .titleOnee {
            line-height: 1.5rem;
            padding-right: 5px;
          }

          .input11 {
            display: flex;
            letter-spacing: 1px;
            width: 60%;
            font-size: 14px;
            padding: 10px 10px;
            font-family: 'Roboto', sans-serif;
            color: #263238;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            outline: none;
            background-color: transparent;


            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              font-size: 14px;
            }
          }


        }


      }

      .cateAge {
        //background-color: red;
        width: 100%;
        display: flex;
        flex-direction: column;

        .sectionn {
          padding-right: 0px;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          background-color: rgba(#C1C1C1, 0.4);
          border-radius: 0;
          padding-left: 15px;
        }

        .displayy {
          margin-top: 50px;
          padding-left: 25px;
          display: flex;
          align-items: center;

          .img {
            width: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            border: none;

            .imgIn {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: self-start;

              .titleTwo {
                padding-bottom: 5px;
              }
            }


            .imgShow {
              height: 200px;
              border: none;
            }
          }
          .picIn{
            padding-left: 25px;
            display: flex;
            align-items: center;
            flex-direction:column;
            .imgIn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: self-start;

              .titleTwo {
                padding-bottom: 5px;
              }
            }
            .imgShow {
              height: 200px;
              border: none;
            }
          }
        }

      }

      .desc {
        margin-top: 50px;
        padding-bottom: 20px;

        .ds {
          width: 100%;
          text-align: left;
          margin-left: 25px;
          color: var(--black);
          font-family: var(--montserrat);
          margin-bottom: 25px;
        }

        .sectionn {
          padding-right: 0px;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          background-color: rgba(#C1C1C1, 0.4);
          border-radius: 0;
          padding-left: 15px;
        }

        .wrapperClassName {
          width: 80%;
          margin-inline: auto;
          border: 1px solid var(--black);
        }

        .editorClassName {
          height: 200px;
        }
      }

      .audio {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .sectionn {
          padding-right: 0px;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          background-color: rgba(#C1C1C1, 0.4);
          border-radius: 0;
          padding-left: 15px;
        }

        .ct {
          margin-left: 25px;
          margin-top: 30px;
          display: flex;
        }
      }

      .tags {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .sectionn {
          padding-right: 0px;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          background-color: rgba(#C1C1C1, 0.4);
          border-radius: 0;
          padding-left: 15px;
        }

        .cr {
          display: flex;
          margin-top: 30px;

          .inp {
            margin-left: 20px;
          }

          .tgWr {
            width: 95%;
            display: flex;
            grid-gap: 20px 20px;
            flex-wrap: wrap;
            position: relative;

            .tg {
             display: flex;


              border: 1px solid var(--blue);
              color: var(--blue);
              text-align: center;
              justify-content: center;
              align-items: center;
              padding: 10px 15px;
              border-radius: 25px;
              margin-left: 10px;
              font-size: 12px;
              width: auto;
              cursor: pointer;
             width: fit-content;
              &.yes{
                border:1px solid var(--blue);
                color: white;
                background-color: var(--blue);

              }
            }
          }
        }


      }
      .isFree {
        display: flex;
        flex-direction: column;

        .bg {
          padding-right: 0px;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          background-color: rgba(#C1C1C1, 0.4);
          border-radius: 0;
          padding-left: 15px;
        }

        .gh {
          display: flex;
          margin: 30px 0 0px 25px;
          align-items: center;

          .ft {
            width: 50%;
            display: flex;
            align-items: center;
          }

          .submit {
            margin-left: 20px;
            padding: 10px 20px;
            color: white;
            background-color: #007BFF;
            border-radius: 5px;
            border: none;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.bg{
  transition: background-color ease-in-out 1s;
}