@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
:root{
  --sc:#FFFFFF;
  --link:#FBFBFB;
  --linkLeft:#007BFF;
  --white:rgb(256,256,256);
  --montserrat:'Montserrat', sans-serif;
  --black:#000;
  --blue: #8344E9;

}