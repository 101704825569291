.popVideo{
  background-color: white;
  //z-index: 10000!important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0.5);
  z-index: -1000;
  transition: transform ease-in-out 0.4s;
  width: 100%;
  opacity: 0;
  height: auto;
  &.ok{
    transform: scale(1.0) translate(-52%,-50%);
    z-index: 1000;
    transition: transform ease-in-out .4s;
    height: auto;
    width: 100%;
    opacity: 1;
    .back{
      width: 60%;
      //margin-inline: auto;
      //background-color: #F1F3F4;
      position: absolute;
      background-color: white;
      //margin-left: 12%;
      //top: -330px;
      left: 50%;
      top: 50%;
      transform: translate(-35%,-50%);
      overflow-y: scroll;
      height: 80vh;
      border: 1px solid #eee;
      //transition: 1s;
      z-index: 2;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      .looper{


        height: auto;
        width: 90%;
        padding: 20px 0 40px 0;
        font-size: 14px;
        margin-inline: auto;
        .qw{
          padding-bottom: 10px;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
        }

        .ooper{
          display: flex;
          //background-color: red;
          height: auto;
          width: 100%;
          .img{
            //object-fit: cover;
            background-repeat: no-repeat;
            height: 200px;
            width: 200px;
            //background-image: url("../../store/a.jpg");
            //background-color: blue;
            background-position: center;
            background-size: cover;
          }
          .right{
            padding-left: 20px;
            display: flex;
            width: auto;
            flex-direction: column;
            align-items: flex-start;

            .r1{
              padding-bottom: 20px;
              font-weight: bold;
              //font-size:18px ;
              &:nth-child(5){
                display: flex;
                div{
                  padding-right: 20px;
                }
              }
              span{
                font-weight: 500;
                font-style: italic;
                //font-size: 16px;
              }
            }
          }

        }
        .des{
          padding-top: 10px;
          border-top: 1px solid #dddddd;
          display: flex;
          flex-direction: column;
          .tit{
            text-align: left;
            font-weight: bold;
            padding-bottom: 10px;
            .edits{
              font-size: 18px!important;
            }
          }
          .tex{
            text-align: justify;
          }
        }
        .tgg{
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          padding-bottom: 50px;
          .tit{
            text-align: left;
            font-weight: bold;
            padding-bottom: 15px;
          }
          .tags{
            display: flex;
            flex-wrap: wrap;

            grid-gap: 20px;

            .tgs{
              color: var(--blue);
              padding: 10px 15px;
              border: 1px solid var(--blue);
              border-radius: 20px;
            }
          }

        }
        .audi{
          display: flex;
          .tit{
            display: flex;
            align-items:center;
            text-align: left;
            font-weight: bold;
            padding-bottom: 10px;
            .titi{
              margin-right: 20px;
            }
            span{
              font-weight: 500;
              font-style: italic;
            }
          }
        }
        .more{
          display: flex;
          align-items: center;
          justify-content: end;
          margin-top: 30px;
          margin-bottom: 10px;
          .edit{
            font-size: 16px;
            border: 1px solid var(--blue);
            background-color: var(--blue);
            color: white;
            border-radius: 15px;
            padding: 10px 25px;
            cursor: pointer;
            margin-right: 20px;
          }
          .del{
            font-size: 16px;
            border: 1px solid var(--blue);
            background-color: var(--blue);
            color: white;
            border-radius: 15px;
            padding: 10px 25px;
            margin-right: 20px;
            cursor: pointer;
          }
        }

      }
    }
  }
}